import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timer-container" }

import Digit from "./DigitBle.vue";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'TimeBle',
  props: {
  hours: {
    type: Number,
    required: true,
  },
  minutes: {
    type: Number,
    required: true,
  },
  seconds: {
    type: Number,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Digit, {
      digit: __props.hours,
      title: "HOURS"
    }, null, 8, ["digit"]),
    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "separator-container" }, [
      _createElementVNode("span", { class: "separator" }),
      _createElementVNode("span")
    ], -1)),
    _createVNode(Digit, {
      digit: __props.minutes,
      title: "MINUTES"
    }, null, 8, ["digit"]),
    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "separator-container" }, [
      _createElementVNode("span", { class: "separator" }),
      _createElementVNode("span")
    ], -1)),
    _createVNode(Digit, {
      digit: __props.seconds,
      title: "SECONDS"
    }, null, 8, ["digit"])
  ]))
}
}

})