import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex noMargin" }
const _hoisted_2 = { class: "flexc" }
const _hoisted_3 = {
  key: 0,
  class: "center padding"
}
const _hoisted_4 = {
  key: 1,
  class: "flex"
}
const _hoisted_5 = {
  key: 2,
  class: "center full"
}
const _hoisted_6 = {
  key: 3,
  class: "center padding"
}
const _hoisted_7 = {
  key: 4,
  class: "center"
}
const _hoisted_8 = { class: "flex" }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = {
  key: 0,
  class: "flexb"
}
const _hoisted_12 = { class: "flex" }
const _hoisted_13 = {
  key: 0,
  class: "flex"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  key: 1,
  class: "flex"
}
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "flex" }

import { useTimer } from "vue-timer-hook";
import { useTime } from "vue-timer-hook";
import TimerBle from "./TimerBle.vue";
import TimeBle from "./TimeBle.vue";
import PercentBle from "./PercentBle.vue";
import PercentDigit from "./PercentDigit.vue";
import Button from "./ButtonBle.vue";
import { ref, watchSyncEffect } from "vue";
import { watchEffect } from "vue";
import Digit from "./DigitBle.vue";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'HelloWorld',
  props: {
  expiryTimestamp: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

//import {  watchEffect, onMounted } from "vue";
const props = __props;

var timeRange = 900;
if (localStorage.getItem("timeRaange")) {
  timeRange = Number(localStorage.getItem("timeRaange"));
}
var selected = ref(timeRange);
var slowTime = 90;
var fastTime = 30;
if (localStorage.getItem("slow")) {
  slowTime = Number(localStorage.getItem("slow"));
}
if (localStorage.getItem("fast")) {
  fastTime = Number(localStorage.getItem("fast"));
}
var slowTimeRef = ref(slowTime);
var fastTimeRef = ref(fastTime);
var showTimers = ref(false);
var showTimeRanges = ref(false);
var showTimeStints = ref(false);
var swap = ref(false);
var percent = ref(0);
var percentDec = ref("");
var percentFrac = ref("");
var generalTimer = new Date();
var lastTimer = 0;
var lastSlowTimer = 0;
var goPercent = false;
var progress = 0;
var slowTimes = -1;
var fastTimes = 0;
var goTimer = ref(false);
var expiredCheck = false;
var generalCounter = 0;
var correctionCounter = 0;
var running = false;
var drift = 0;
var driftRef = ref(0);
var stopDate = new Date();
var innerDate = new Date();
var correction = 0;
var correctionRef = ref(0);
var calculate = false;
var slowTotalRef = ref(0);
var fastTotalRef = ref(0);
var terminalCounter = 0;
var timer1 = new Date();
var timer2 = new Date();
var currentCounter = ref(0);
var slowFast = true;
var littlePercent = 0;
var littlePercentRef = ref("0");
var percentUp = ref(0);
var percentUpText = ref("0");
var tickRunning = false;
var lastTime = 0;
var lastTick = 0;
var difference = 0;
// eslint-disable-next-line @typescript-eslint/no-empty-function
var tickTimer = setTimeout(() => {}, 2000);
const timer = useTimer(getTimestampRange(timeRange), false);
const timerSlow = useTimer(getTimestampRange(slowTime), false);
const timeD = useTime();

function getTimestampRange(range: number, correction = 0): number {
  generalTimer = new Date();
  const rangeMil = range * 1000;
  const correctionMil = correction; // * 1000;
  return generalTimer.getTime() + rangeMil + correctionMil;
}

const reload = () => {
  // Restarts to 10 minutes timer
  goPercent = false;
  currentCounter.value = slowTime;
  const start = getTimestampRange(0);
  const end = getTimestampRange(timeRange);
  setTimeout(() => {
    slowFast = true;
    clearTimeout(tickTimer);
    //tick();
    tickRunning = false;
    timer.restart(getTimestampRange(timeRange));
    timerSlow.restart(getTimestampRange(slowTime));
    goPercent = true;
    computePercent(start, end);
    calculateTimes();
    drift = 0;
    driftRef.value = 0;
    correction = 0;
    correctionRef.value = 0;
    terminalCounter = 0;
    percentUp.value = 0;
    percentUpText.value = "0";
    littlePercentRef.value = "0";
    correction = 0;
    correctionRef.value = 0;
  }, 200);
};
const reset = () => {
  // Restarts to 10 minutes timer
  timer.restart(getTimestampRange(timeRange));
  timer.pause();
  timerSlow.restart(getTimestampRange(slowTime));
  timerSlow.pause();
  showTimers.value = false;
  goPercent = false;
  calculateTimes();
  drift = 0;
  driftRef.value = 0;
  correction = 0;
  correctionRef.value = 0;
  terminalCounter = 0;
  currentCounter.value = slowTime;
  slowFast = true;
  percentUp.value = 0;
  percentUpText.value = "0";
  littlePercentRef.value = "0";
  correction = 0;
  correctionRef.value = 0;
  clearTimeout(tickTimer);
  tickRunning = false;
};
const startTimers = () => {
  slowFast = true;
  currentCounter.value = slowTime;
  clearTimeout(tickTimer);
  //tick();
  tickRunning = false;
  const start = getTimestampRange(0);
  const end = getTimestampRange(timeRange);
  //time.setSeconds(time.getSeconds() + timeRange);
  timer.restart(getTimestampRange(timeRange));
  //timer.start();
  timerSlow.restart(getTimestampRange(slowTime));
  //timerSlow.start();
  goPercent = true;
  computePercent(start, end);
  calculateTimes();
  running = true;
  drift = 0;
  driftRef.value = 0;
  correction = 0;
  correctionRef.value = 0;
  slowTotalRef.value = slowTimes;
  fastTotalRef.value = fastTimes;
  terminalCounter = 0;
  percentUp.value = 0;
  percentUpText.value = "0";
  littlePercentRef.value = "0";
  correction = 0;
  correctionRef.value = 0;
};
const pauseTimers = () => {
  timer.pause();
  timerSlow.pause();
  goPercent = false;
  terminalCounter = 0;
  clearTimeout(tickTimer);
  tickRunning = false;
};
const resumeTimers = () => {
  timer.resume();
  timerSlow.resume();
  const start = getTimestampRange(0);
  console.log(progress);
  const end = getTimestampRange(timeRange - progress / 1000);
  goPercent = true;
  running = true;
  computePercent(start, end);
  drift = 0;
  driftRef.value = 0;
  correction = 0;
  correctionRef.value = 0;
  terminalCounter = 0;
  correction = 0;
  correctionRef.value = 0;
  clearTimeout(tickTimer);
  //tick();
  tickRunning = false;
};
const computePercent = (start: number, end: number) => {
  const now = new Date();
  const nowTimestamp = now.getTime();
  const total = end - start;
  progress = nowTimestamp - start;
  const p = 99.99 - (progress / total) * 100;
  percentUp.value = (progress / total) * 100;
  percentUpText.value = percentUp.value.toFixed(2).split(".")[0];
  percent.value = p;
  const percentText = p.toFixed(2).split(".");
  percentDec.value = percentText[0];
  if (percentDec.value.length < 2) percentDec.value = "0" + percentDec.value;
  percentFrac.value = percentText[1];
  if (percentFrac.value.length < 2) percentFrac.value = "0" + percentFrac.value;
  if (goPercent === true) {
    setTimeout(() => {
      computePercent(start, end);
    }, 100);
  }
};
function calculateTimes() {
  let sum = 0;
  let slowFast = false;
  slowTimes = -1;
  fastTimes = 0;
  while (sum < timeRange) {
    if (slowFast === false) {
      sum += slowTime;
      slowTimes++;
      slowFast = !slowFast;
    } else {
      sum += fastTime;
      fastTimes++;
      slowFast = !slowFast;
    }
  }
}
function saveTimeRange() {
  localStorage.setItem("timeRaange", timeRange.toString());
}
function saveTimeStints() {
  slowTime = slowTimeRef.value;
  fastTime = fastTimeRef.value;
  localStorage.setItem("slow", slowTime.toString());
  localStorage.setItem("fast", fastTime.toString());
}
function setTimeRanege(range: number) {
  timeRange = range;
  selected.value = timeRange;
}
function setCorrection(c: number) {
  correction += c;
  //currentCounter.value += c;
  correctionRef.value += c;
}
function tick() {
  //console.log("tick", slowFast, currentCounter.value);
  currentCounter.value--;
  if (currentCounter.value < 1) {
    slowFast = !slowFast;
    if (slowFast === true) {
      currentCounter.value = slowTime + correction;
      slowTimes--;
      swap.value = false;
      correction = 0;
      correctionRef.value = 0;
    } else {
      currentCounter.value = fastTime + correction;
      fastTimes--;
      swap.value = true;
      correction = 0;
      correctionRef.value = 0;
    }
  }
  if (slowFast === true) {
    const p = 99.99 - (currentCounter.value / slowTime) * 100;
    littlePercentRef.value = p.toFixed(2).split(".")[0];
  } else {
    const p = 99.99 - (currentCounter.value / fastTime) * 100;
    littlePercentRef.value = p.toFixed(2).split(".")[0];
  }
  let now = new Date();
  lastTick = now.getTime();
  difference += lastTick - lastTime;
  console.log(lastTick - lastTime, difference);
  tickTimer = setTimeout(tick, 1000);
}
watchEffect(async () => {
  if (timer.isExpired.value) {
    console.warn("IsExpired");
    timerSlow.restart(getTimestampRange(timeRange));
    timerSlow.pause();
    showTimers.value = false;
    goPercent = false;
    terminalCounter = 0;
    currentCounter.value = slowTime;
    slowFast = true;
    percentUp.value = 0;
    percentUpText.value = "0";
    littlePercentRef.value = "0";
    swap.value = false;
    correction = 0;
    correctionRef.value = 0;
    clearTimeout(tickTimer);
  }
  if (timer.seconds.value) {
    let now = new Date();
    //console.log(now.getTime() - lastTime);
    lastTime = now.getTime();
    if (tickRunning === false) {
      tickRunning = true;
      tick();
    }
    //currentCounter.value--;
    //console.log(slowFast, currentCounter.value);
    /*
    if (currentCounter.value < 1) {
      slowFast = !slowFast;
      if (slowFast === true) {
        currentCounter.value = slowTime + correction;
        slowTimes--;
        swap.value = false;
        correction = 0;
        correctionRef.value = 0;
      } else {
        currentCounter.value = fastTime + correction;
        fastTimes--;
        swap.value = true;
        correction = 0;
        correctionRef.value = 0;
      }
    }
    if (slowFast === true) {
      const p = 99.99 - (currentCounter.value / slowTime) * 100;
      littlePercentRef.value = p.toFixed(2).split(".")[0];
    } else {
      const p = 99.99 - (currentCounter.value / fastTime) * 100;
      littlePercentRef.value = p.toFixed(2).split(".")[0];
    }
      */
  }
  /*
  if (timerSlow.isExpired.value) {
    console.warn("Slow IsExpired");
    terminalCounter++;
    if (running === true) {
      running = false;
      stopDate = new Date();
    }
  }
  if (timerSlow.seconds.value) {
    //console.warn("Slow running");
    timer1 = new Date();
  }
  if (timer.seconds.value) {
    if (currentCounter.value < 1) {
      slowFast = !slowFast;
      if (slowFast === true) {
        currentCounter.value = slowTime;
      } else {
        currentCounter.value = fastTime;
      }
    }
    currentCounter.value = currentCounter.value - 1;
    console.log(slowFast, currentCounter.value);
    if (timerSlow.isExpired.value) {
      calculate = true;
      console.warn("Slow IsExpired2");
      goTimer.value = true;
      console.log("tCounter", terminalCounter);
      terminalCounter = 0;
    }
    //console.log("drift", drift);
    if (goTimer.value === true) {
      running = true;
      goTimer.value = false;
      if (drift >= 1000) {
        correction += drift * -1;
        console.log("correction", correction);
        correctionRef.value = correction;
      }
      if (swap.value === false) {
        timerSlow.restart(getTimestampRange(fastTime, correction));
        fastTimes--;
        swap.value = true;
      } else {
        timerSlow.restart(getTimestampRange(slowTime, correction));
        slowTimes--;
        swap.value = false;
      }
    }
    if (calculate === true) {
      if (correction != 0) {
        drift = 0;
        correction = 0;
        correctionRef.value = correction;
        console.log("drift", drift, "correction", correction);
      }
      innerDate = new Date();
      drift += innerDate.getTime() - stopDate.getTime();
      console.log("drift calculate", drift);
      driftRef.value = drift;
      calculate = false;
    }
    timer2 = new Date();
    console.log("Timer diff", timer2.getTime() - timer1.getTime());
  }
    */
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("h3", _hoisted_1, [
        _createVNode(TimeBle, {
          seconds: _unref(timeD).seconds,
          minutes: _unref(timeD).minutes,
          hours: _unref(timeD).hours
        }, null, 8, ["seconds", "minutes", "hours"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_unref(showTimeRanges) === false && _unref(showTimeStints) === false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("h2", null, "Current time range: " + _toDisplayString(_unref(timeRange) / 60) + " minutes", 1),
              (_unref(showTimers) === false)
                ? (_openBlock(), _createBlock(Button, {
                    key: 0,
                    type: "button",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => {
            _isRef(showTimeRanges) //@ts-ignore
 ? showTimeRanges.value = true : showTimeRanges = true;
            _isRef(showTimeStints) //@ts-ignore
 ? showTimeStints.value = false : showTimeStints = false;
          })
                  }, {
                    default: _withCtx(() => _cache[33] || (_cache[33] = [
                      _createTextVNode("Change")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_unref(showTimeRanges) === true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("p", null, [
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => {
              setTimeRanege(900);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 900 })
                }, {
                  default: _withCtx(() => _cache[34] || (_cache[34] = [
                    _createTextVNode("15 minutes")
                  ])),
                  _: 1
                }, 8, ["class"]),
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => {
              setTimeRanege(1200);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 1200 })
                }, {
                  default: _withCtx(() => _cache[35] || (_cache[35] = [
                    _createTextVNode("20 minutes")
                  ])),
                  _: 1
                }, 8, ["class"]),
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => {
              setTimeRanege(1500);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 1500 })
                }, {
                  default: _withCtx(() => _cache[36] || (_cache[36] = [
                    _createTextVNode("25 minutes")
                  ])),
                  _: 1
                }, 8, ["class"])
              ]),
              _createElementVNode("p", null, [
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => {
              setTimeRanege(1800);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 1800 })
                }, {
                  default: _withCtx(() => _cache[37] || (_cache[37] = [
                    _createTextVNode("30 minutes")
                  ])),
                  _: 1
                }, 8, ["class"]),
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => {
              setTimeRanege(2100);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 2100 })
                }, {
                  default: _withCtx(() => _cache[38] || (_cache[38] = [
                    _createTextVNode("35 minutes")
                  ])),
                  _: 1
                }, 8, ["class"]),
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => {
              setTimeRanege(2400);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 2400 })
                }, {
                  default: _withCtx(() => _cache[39] || (_cache[39] = [
                    _createTextVNode("40 minutes")
                  ])),
                  _: 1
                }, 8, ["class"])
              ]),
              _createElementVNode("p", null, [
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => {
              setTimeRanege(2700);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 2700 })
                }, {
                  default: _withCtx(() => _cache[40] || (_cache[40] = [
                    _createTextVNode("45 minutes")
                  ])),
                  _: 1
                }, 8, ["class"]),
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[8] || (_cache[8] = ($event: any) => {
              setTimeRanege(3000);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 3000 })
                }, {
                  default: _withCtx(() => _cache[41] || (_cache[41] = [
                    _createTextVNode("50 minutes")
                  ])),
                  _: 1
                }, 8, ["class"]),
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[9] || (_cache[9] = ($event: any) => {
              setTimeRanege(3300);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 3300 })
                }, {
                  default: _withCtx(() => _cache[42] || (_cache[42] = [
                    _createTextVNode("55 minutes")
                  ])),
                  _: 1
                }, 8, ["class"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_unref(showTimeRanges) === true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(Button, {
                type: "button",
                onClick: _cache[10] || (_cache[10] = ($event: any) => {
            _isRef(showTimeRanges) //@ts-ignore
 ? showTimeRanges.value = false : showTimeRanges = false;
            saveTimeRange();
          })
              }, {
                default: _withCtx(() => _cache[43] || (_cache[43] = [
                  _createTextVNode("Save")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_unref(showTimeStints) === false && _unref(showTimeRanges) === false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("h2", null, "Timers: " + _toDisplayString(_unref(slowTime)) + "s slow | " + _toDisplayString(_unref(fastTime)) + "s fast", 1),
              (_unref(showTimers) === false)
                ? (_openBlock(), _createBlock(Button, {
                    key: 0,
                    type: "button",
                    onClick: _cache[11] || (_cache[11] = ($event: any) => {
            _isRef(showTimeStints) //@ts-ignore
 ? showTimeStints.value = true : showTimeStints = true;
            _isRef(showTimeRanges) //@ts-ignore
 ? showTimeRanges.value = false : showTimeRanges = false;
          })
                  }, {
                    default: _withCtx(() => _cache[44] || (_cache[44] = [
                      _createTextVNode("Change Stints")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_unref(showTimeStints) === true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("h2", null, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[12] || (_cache[12] = ($event: any) => (_isRef(slowTimeRef) //@ts-ignore
 ? slowTimeRef.value -= 100 : slowTimeRef -= 100))
                  }, {
                    default: _withCtx(() => _cache[45] || (_cache[45] = [
                      _createTextVNode("-100")
                    ])),
                    _: 1
                  }),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[13] || (_cache[13] = ($event: any) => (_isRef(slowTimeRef) //@ts-ignore
 ? slowTimeRef.value -= 10 : slowTimeRef -= 10))
                  }, {
                    default: _withCtx(() => _cache[46] || (_cache[46] = [
                      _createTextVNode("-10")
                    ])),
                    _: 1
                  }),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[14] || (_cache[14] = ($event: any) => (_isRef(slowTimeRef) //@ts-ignore
 ? slowTimeRef.value-- : slowTimeRef--))
                  }, {
                    default: _withCtx(() => _cache[47] || (_cache[47] = [
                      _createTextVNode("-1")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("h1", null, _toDisplayString(_unref(slowTimeRef)), 1),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[15] || (_cache[15] = ($event: any) => (_isRef(slowTimeRef) //@ts-ignore
 ? slowTimeRef.value++ : slowTimeRef++))
                  }, {
                    default: _withCtx(() => _cache[48] || (_cache[48] = [
                      _createTextVNode("+1")
                    ])),
                    _: 1
                  }),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[16] || (_cache[16] = ($event: any) => (_isRef(slowTimeRef) //@ts-ignore
 ? slowTimeRef.value += 10 : slowTimeRef += 10))
                  }, {
                    default: _withCtx(() => _cache[49] || (_cache[49] = [
                      _createTextVNode("+10")
                    ])),
                    _: 1
                  }),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[17] || (_cache[17] = ($event: any) => (_isRef(slowTimeRef) //@ts-ignore
 ? slowTimeRef.value += 100 : slowTimeRef += 100))
                  }, {
                    default: _withCtx(() => _cache[50] || (_cache[50] = [
                      _createTextVNode("+100")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[18] || (_cache[18] = ($event: any) => (_isRef(fastTimeRef) //@ts-ignore
 ? fastTimeRef.value -= 100 : fastTimeRef -= 100))
                  }, {
                    default: _withCtx(() => _cache[51] || (_cache[51] = [
                      _createTextVNode("-100")
                    ])),
                    _: 1
                  }),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[19] || (_cache[19] = ($event: any) => (_isRef(fastTimeRef) //@ts-ignore
 ? fastTimeRef.value -= 10 : fastTimeRef -= 10))
                  }, {
                    default: _withCtx(() => _cache[52] || (_cache[52] = [
                      _createTextVNode("-10")
                    ])),
                    _: 1
                  }),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[20] || (_cache[20] = ($event: any) => (_isRef(fastTimeRef) //@ts-ignore
 ? fastTimeRef.value-- : fastTimeRef--))
                  }, {
                    default: _withCtx(() => _cache[53] || (_cache[53] = [
                      _createTextVNode("-1")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("h1", null, _toDisplayString(_unref(fastTimeRef)), 1),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[21] || (_cache[21] = ($event: any) => (_isRef(fastTimeRef) //@ts-ignore
 ? fastTimeRef.value++ : fastTimeRef++))
                  }, {
                    default: _withCtx(() => _cache[54] || (_cache[54] = [
                      _createTextVNode("+1")
                    ])),
                    _: 1
                  }),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[22] || (_cache[22] = ($event: any) => (_isRef(fastTimeRef) //@ts-ignore
 ? fastTimeRef.value += 10 : fastTimeRef += 10))
                  }, {
                    default: _withCtx(() => _cache[55] || (_cache[55] = [
                      _createTextVNode("+10")
                    ])),
                    _: 1
                  }),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[23] || (_cache[23] = ($event: any) => (_isRef(fastTimeRef) //@ts-ignore
 ? fastTimeRef.value += 100 : fastTimeRef += 100))
                  }, {
                    default: _withCtx(() => _cache[56] || (_cache[56] = [
                      _createTextVNode("+100")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _createVNode(Button, {
                type: "button",
                onClick: _cache[24] || (_cache[24] = ($event: any) => {
            saveTimeStints();
            _isRef(showTimeStints) //@ts-ignore
 ? showTimeStints.value = false : showTimeStints = false;
          })
              }, {
                default: _withCtx(() => _cache[57] || (_cache[57] = [
                  _createTextVNode("Save")
                ])),
                _: 1
              }),
              _createVNode(Button, {
                type: "button",
                onClick: _cache[25] || (_cache[25] = ($event: any) => (_isRef(showTimeStints) //@ts-ignore
 ? showTimeStints.value = false : showTimeStints = false))
              }, {
                default: _withCtx(() => _cache[58] || (_cache[58] = [
                  _createTextVNode("Cancel")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _cache[65] || (_cache[65] = _createElementVNode("div", null, " ", -1)),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(TimerBle, {
          seconds: _unref(timer).seconds,
          minutes: _unref(timer).minutes
        }, null, 8, ["seconds", "minutes"]),
        (_unref(showTimers) === true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createVNode(PercentDigit, {
                digit: _unref(percent),
                fraction: true,
                title: "PERCENT"
              }, null, 8, ["digit"]),
              _createElementVNode("div", {
                class: "range",
                style: _normalizeStyle({ '--p': _unref(percentUpText) })
              }, _cache[59] || (_cache[59] = [
                _createElementVNode("div", { class: "range__label" }, "Progress", -1)
              ]), 4)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(Button, {
          type: "button",
          onClick: _cache[26] || (_cache[26] = ($event: any) => {
          startTimers();
          _isRef(showTimers) //@ts-ignore
 ? showTimers.value = true : showTimers = true;
        })
        }, {
          default: _withCtx(() => _cache[60] || (_cache[60] = [
            _createTextVNode("Start")
          ])),
          _: 1
        }),
        _createVNode(Button, {
          type: "button",
          onClick: _cache[27] || (_cache[27] = ($event: any) => (pauseTimers()))
        }, {
          default: _withCtx(() => _cache[61] || (_cache[61] = [
            _createTextVNode("Pause")
          ])),
          _: 1
        }),
        _createVNode(Button, {
          type: "button",
          onClick: _cache[28] || (_cache[28] = ($event: any) => (resumeTimers()))
        }, {
          default: _withCtx(() => _cache[62] || (_cache[62] = [
            _createTextVNode("Resume")
          ])),
          _: 1
        }),
        _createVNode(Button, {
          type: "button",
          onClick: _cache[29] || (_cache[29] = ($event: any) => (reload()))
        }, {
          default: _withCtx(() => _cache[63] || (_cache[63] = [
            _createTextVNode(" Restart ")
          ])),
          _: 1
        }),
        _createVNode(Button, {
          type: "button",
          onClick: _cache[30] || (_cache[30] = ($event: any) => (reset()))
        }, {
          default: _withCtx(() => _cache[64] || (_cache[64] = [
            _createTextVNode(" Reset ")
          ])),
          _: 1
        })
      ])
    ]),
    (_unref(showTimers) === true)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([{ intervalFast: _unref(swap), intervalSlow: !_unref(swap) }, "full"])
        }, [
          _cache[71] || (_cache[71] = _createElementVNode("p", null, null, -1)),
          (_unref(swap) === true)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_13, [
                _cache[66] || (_cache[66] = _createTextVNode(" Fast ")),
                (_unref(fastTimes) > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_unref(fastTimes)) + " left of " + _toDisplayString(_unref(fastTotalRef)) + " total.", 1))
                  : _createCommentVNode("", true),
                (_unref(fastTimes) < 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_15, "last"))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_unref(swap) === false)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_16, [
                _cache[67] || (_cache[67] = _createTextVNode(" Slow ")),
                (_unref(slowTimes) > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_unref(slowTimes)) + " left of " + _toDisplayString(_unref(slowTotalRef)) + " total.", 1))
                  : _createCommentVNode("", true),
                (_unref(slowTimes) < 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_18, "last"))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("h2", _hoisted_19, [
            _createVNode(Button, {
              type: "button",
              onClick: _cache[31] || (_cache[31] = ($event: any) => (setCorrection(-1)))
            }, {
              default: _withCtx(() => _cache[68] || (_cache[68] = [
                _createTextVNode("Synch -1s")
              ])),
              _: 1
            }),
            _createVNode(PercentDigit, {
              digit: _unref(currentCounter),
              fraction: false,
              title: "SECONDS"
            }, null, 8, ["digit"]),
            _createVNode(Button, {
              type: "button",
              onClick: _cache[32] || (_cache[32] = ($event: any) => (setCorrection(1)))
            }, {
              default: _withCtx(() => _cache[69] || (_cache[69] = [
                _createTextVNode("Synch +1s")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", {
            class: "range",
            style: _normalizeStyle({ '--p': _unref(littlePercentRef) })
          }, _cache[70] || (_cache[70] = [
            _createElementVNode("div", { class: "range__label" }, "Progress", -1)
          ]), 4),
          _createElementVNode("h2", null, [
            _createElementVNode("span", null, "Correction: " + _toDisplayString(_unref(correctionRef)) + " seconds", 1)
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}
}

})