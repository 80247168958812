<template>
  <div class="flexH">
    <HelloWorld :expiryTimestamp="time" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HelloWorld from "./components/HelloWorld.vue";

@Options({
  components: {
    HelloWorld,
  },
})
export default class App extends Vue {
  time = new Date().setSeconds(new Date().getSeconds() + 900);
}
</script>
<style>
html,
body {
  margin: 0;
  font-family: Arial;
  text-align: left;
  color: #404549;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
h2 {
  margin-top: 20px;
}
</style>
<style scoped>
.container {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.header-bg {
  background-color: #404549;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header h1 {
  margin: 20px 0;
}

.h1 {
  color: white;
}

.separator {
  height: 0px;
  margin-top: 30px;
  border: dashed 2px #404549;
}
.flexH {
  /*display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;*/
}
</style>
