<template>
  <div class="timer-container">
    <div class="container">
      <span class="title">{{ title }}</span>
      <div class="digital-container">
        <span v-for="digit in digitsWhole" :key="digit" class="single-digit">
          {{ digit }}
        </span>
      </div>
    </div>
    <span v-if="digitsFraction.length > 0" class="separator-container"
      ><span class="separator" /><span
    /></span>
    <div class="container">
      <div v-if="digitsFraction.length > 0" class="container">
        <span class="title">fraction</span>
        <div class="digital-container">
          <span
            v-for="digit in digitsFraction"
            :key="digit"
            class="single-digit"
          >
            {{ digit }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { watchEffect } from "vue";
import { ref } from "vue";

var leftDigit = ref("0");
var rightDigit = ref("0");
var digitsWhole = ref([]);
var digitsFraction = ref([]);
// eslint-disable-next-line no-undef
const props = defineProps({
  digit: {
    type: Number,
    require: true,
  },
  fraction: {
    type: Boolean,
    require: false,
  },
  title: {
    type: String,
    require: false,
  },
});

// eslint-disable-next-line no-undef
watchEffect(async () => {
  if (props.digit) {
    if (props.fraction) {
      const fixed = props.digit.toFixed(2).split(".");
      //console.log(fixed);
      let whole = fixed[0].split("");
      if (whole.length > 0) {
        if (whole.length < 2) {
          whole.unshift("0");
        }
        digitsWhole = whole;
      }
      let fraction = fixed[1].split("");
      if (fraction.length > 0) {
        digitsFraction = fraction;
      }
    } else {
      let whole = props.digit.toString();
      whole = whole.split("");
      if (whole.length > 0) {
        if (whole.length < 2) {
          whole.unshift("0");
        }
        digitsWhole = whole;
      }
    }
    /*percentDec.value = percentText[0];
    if (percentDec.value.length < 2) percentDec.value = "0" + percentDec.value;
    percentFrac.value = percentText[1];
    if (percentFrac.value.length < 2) percentFrac.value = "0" + percentFrac.value;*/
    //console.log(props.digit);
    leftDigit = computed(() =>
      props.digit >= 10 ? props.digit.toString()[0] : "0"
    );
    rightDigit = computed(() =>
      props.digit >= 10 ? props.digit.toString()[1] : props.digit.toString()
    );
  }
});
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
}
.container:first-child {
  margin-left: 0;
}
.title {
  font-size: 12px;
  margin-bottom: 5px;
}
.digital-container {
  display: flex;
  flex-direction: row;
  padding: 0;
}
.single-digit {
  position: relative;
  display: flex;
  flex: 0 1 25%;
  font-size: 30px;
  background-color: #404549;
  border-radius: 5px;
  padding: 10px 12px;
  color: white;
  margin-right: 2px;
}
.single-digit:first-child {
  margin-right: 2px;
}
.single-digit:after {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  bottom: 50%;
  content: "";
  width: "100%";
  height: 2px;
  background-color: #232323;
  opacity: 0.4;
}
.timer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
}
.separator-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  margin: 0 0 10px 0px;
}
.separator {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #404549;
  border-radius: 6px;
  margin: 5px 0px;
}
</style>
