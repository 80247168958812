<!-- eslint-disable no-undef -->
<template>
  <div>
    <h3 class="flex noMargin">
      <TimeBle
        :seconds="timeD.seconds"
        :minutes="timeD.minutes"
        :hours="timeD.hours"
      />
    </h3>
    <div class="flexc">
      <div
        class="center padding"
        v-if="showTimeRanges === false && showTimeStints === false"
      >
        <h2>Current time range: {{ timeRange / 60 }} minutes</h2>
        <Button
          v-if="showTimers === false"
          type="button"
          @click="
            showTimeRanges = true;
            showTimeStints = false;
          "
          >Change</Button
        >
      </div>
      <div class="flex" v-if="showTimeRanges === true">
        <p>
          <Button
            type="button"
            @click="
              setTimeRanege(900);
              reset();
            "
            :class="{ selected: selected === 900 }"
            >15 minutes</Button
          >
          <Button
            type="button"
            @click="
              setTimeRanege(1200);
              reset();
            "
            :class="{ selected: selected === 1200 }"
            >20 minutes</Button
          >
          <Button
            type="button"
            @click="
              setTimeRanege(1500);
              reset();
            "
            :class="{ selected: selected === 1500 }"
            >25 minutes</Button
          >
        </p>
        <p>
          <Button
            type="button"
            @click="
              setTimeRanege(1800);
              reset();
            "
            :class="{ selected: selected === 1800 }"
            >30 minutes</Button
          >
          <Button
            type="button"
            @click="
              setTimeRanege(2100);
              reset();
            "
            :class="{ selected: selected === 2100 }"
            >35 minutes</Button
          >
          <Button
            type="button"
            @click="
              setTimeRanege(2400);
              reset();
            "
            :class="{ selected: selected === 2400 }"
            >40 minutes</Button
          >
        </p>
        <p>
          <Button
            type="button"
            @click="
              setTimeRanege(2700);
              reset();
            "
            :class="{ selected: selected === 2700 }"
            >45 minutes</Button
          >
          <Button
            type="button"
            @click="
              setTimeRanege(3000);
              reset();
            "
            :class="{ selected: selected === 3000 }"
            >50 minutes</Button
          >
          <Button
            type="button"
            @click="
              setTimeRanege(3300);
              reset();
            "
            :class="{ selected: selected === 3300 }"
            >55 minutes</Button
          >
        </p>
      </div>
      <div class="center full" v-if="showTimeRanges === true">
        <Button
          type="button"
          @click="
            showTimeRanges = false;
            saveTimeRange();
          "
          >Save</Button
        >
        <!--Button type="button" @click="showTimeRanges = false">Cancel</!--Button//-->
      </div>
      <div
        class="center padding"
        v-if="showTimeStints === false && showTimeRanges === false"
      >
        <h2>Timers: {{ slowTime }}s slow | {{ fastTime }}s fast</h2>
        <Button
          v-if="showTimers === false"
          type="button"
          @click="
            showTimeStints = true;
            showTimeRanges = false;
          "
          >Change Stints</Button
        >
      </div>
      <div class="center" v-if="showTimeStints === true">
        <h2>
          <div class="flex">
            <Button type="button" @click="slowTimeRef -= 100">-100</Button
            ><Button type="button" @click="slowTimeRef -= 10">-10</Button
            ><Button type="button" @click="slowTimeRef--">-1</Button>
            <h1>{{ slowTimeRef }}</h1>
            <Button type="button" @click="slowTimeRef++">+1</Button
            ><Button type="button" @click="slowTimeRef += 10">+10</Button
            ><Button type="button" @click="slowTimeRef += 100">+100</Button>
          </div>
          <div class="flex">
            <Button type="button" @click="fastTimeRef -= 100">-100</Button
            ><Button type="button" @click="fastTimeRef -= 10">-10</Button
            ><Button type="button" @click="fastTimeRef--">-1</Button>
            <h1>{{ fastTimeRef }}</h1>
            <Button type="button" @click="fastTimeRef++">+1</Button
            ><Button type="button" @click="fastTimeRef += 10">+10</Button
            ><Button type="button" @click="fastTimeRef += 100">+100</Button>
          </div>
        </h2>
        <Button
          type="button"
          @click="
            saveTimeStints();
            showTimeStints = false;
          "
          >Save</Button
        >
        <Button type="button" @click="showTimeStints = false">Cancel</Button>
      </div>
    </div>
    <div>&nbsp;</div>
    <div class="flex">
      <TimerBle :seconds="timer.seconds" :minutes="timer.minutes" />
      <!--PercentBle
        :percentLeft="percentDec.split('')[0]"
        :percentRight="percentDec.split('')[1]"
        :fractionLeft="percentFrac.split('')[0]"
        :fractionRight="percentFrac.split('')[1]"
      /-->
      <div v-if="showTimers === true" class="flexb">
        <PercentDigit :digit="percent" :fraction="true" title="PERCENT" />
        <div class="range" :style="{ '--p': percentUpText }">
          <div class="range__label">Progress</div>
        </div>
        <!--h1>{{ percentDec }},</!--h1>
        <h2>{{ percentFrac }} % left</h2//-->
      </div>
    </div>
    <div class="flex">
      <Button
        type="button"
        @click="
          startTimers();
          showTimers = true;
        "
        >Start</Button
      >
      <Button type="button" @click="pauseTimers()">Pause</Button>
      <Button type="button" @click="resumeTimers()">Resume</Button>
      <Button type="button" @click="reload()"> Restart </Button>
      <Button type="button" @click="reset()"> Reset </Button>
    </div>
  </div>
  <div
    v-if="showTimers === true"
    :class="{ intervalFast: swap, intervalSlow: !swap }"
    class="full"
  >
    <p></p>
    <h2 v-if="swap === true" class="flex">
      Fast
      <span v-if="fastTimes > 0"
        >{{ fastTimes }} left of {{ fastTotalRef }} total.</span
      >
      <span v-if="fastTimes < 1">last</span>
    </h2>
    <h2 v-if="swap === false" class="flex">
      Slow
      <span v-if="slowTimes > 0"
        >{{ slowTimes }} left of {{ slowTotalRef }} total.</span
      >
      <span v-if="slowTimes < 1">last</span>
    </h2>
    <h2 class="flex">
      <!--TimerBle :seconds="timerSlow.seconds" :minutes="timerSlow.minutes" //-->
      <Button type="button" @click="setCorrection(-1)">Synch -1s</Button>
      <PercentDigit :digit="currentCounter" :fraction="false" title="SECONDS" />
      <Button type="button" @click="setCorrection(1)">Synch +1s</Button>
    </h2>
    <div class="range" :style="{ '--p': littlePercentRef }">
      <div class="range__label">Progress</div>
    </div>
    <h2>
      <!-- Drift: {{ driftRef }}//-->

      <span>Correction: {{ correctionRef }} seconds</span>
    </h2>
  </div>
</template>

<script setup lang="ts">
//import {  watchEffect, onMounted } from "vue";
import { useTimer } from "vue-timer-hook";
import { useTime } from "vue-timer-hook";
import TimerBle from "./TimerBle.vue";
import TimeBle from "./TimeBle.vue";
import PercentBle from "./PercentBle.vue";
import PercentDigit from "./PercentDigit.vue";
import Button from "./ButtonBle.vue";
import { ref, watchSyncEffect } from "vue";
import { watchEffect } from "vue";
import Digit from "./DigitBle.vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  expiryTimestamp: {
    type: Number,
    required: true,
  },
});

var timeRange = 900;
if (localStorage.getItem("timeRaange")) {
  timeRange = Number(localStorage.getItem("timeRaange"));
}
var selected = ref(timeRange);
var slowTime = 90;
var fastTime = 30;
if (localStorage.getItem("slow")) {
  slowTime = Number(localStorage.getItem("slow"));
}
if (localStorage.getItem("fast")) {
  fastTime = Number(localStorage.getItem("fast"));
}
var slowTimeRef = ref(slowTime);
var fastTimeRef = ref(fastTime);
var showTimers = ref(false);
var showTimeRanges = ref(false);
var showTimeStints = ref(false);
var swap = ref(false);
var percent = ref(0);
var percentDec = ref("");
var percentFrac = ref("");
var generalTimer = new Date();
var lastTimer = 0;
var lastSlowTimer = 0;
var goPercent = false;
var progress = 0;
var slowTimes = -1;
var fastTimes = 0;
var goTimer = ref(false);
var expiredCheck = false;
var generalCounter = 0;
var correctionCounter = 0;
var running = false;
var drift = 0;
var driftRef = ref(0);
var stopDate = new Date();
var innerDate = new Date();
var correction = 0;
var correctionRef = ref(0);
var calculate = false;
var slowTotalRef = ref(0);
var fastTotalRef = ref(0);
var terminalCounter = 0;
var timer1 = new Date();
var timer2 = new Date();
var currentCounter = ref(0);
var slowFast = true;
var littlePercent = 0;
var littlePercentRef = ref("0");
var percentUp = ref(0);
var percentUpText = ref("0");
var tickRunning = false;
var lastTime = 0;
var lastTick = 0;
var difference = 0;
// eslint-disable-next-line @typescript-eslint/no-empty-function
var tickTimer = setTimeout(() => {}, 2000);
const timer = useTimer(getTimestampRange(timeRange), false);
const timerSlow = useTimer(getTimestampRange(slowTime), false);
const timeD = useTime();

function getTimestampRange(range: number, correction = 0): number {
  generalTimer = new Date();
  const rangeMil = range * 1000;
  const correctionMil = correction; // * 1000;
  return generalTimer.getTime() + rangeMil + correctionMil;
}

const reload = () => {
  // Restarts to 10 minutes timer
  goPercent = false;
  currentCounter.value = slowTime;
  const start = getTimestampRange(0);
  const end = getTimestampRange(timeRange);
  setTimeout(() => {
    slowFast = true;
    clearTimeout(tickTimer);
    //tick();
    tickRunning = false;
    timer.restart(getTimestampRange(timeRange));
    timerSlow.restart(getTimestampRange(slowTime));
    goPercent = true;
    computePercent(start, end);
    calculateTimes();
    drift = 0;
    driftRef.value = 0;
    correction = 0;
    correctionRef.value = 0;
    terminalCounter = 0;
    percentUp.value = 0;
    percentUpText.value = "0";
    littlePercentRef.value = "0";
    correction = 0;
    correctionRef.value = 0;
  }, 200);
};
const reset = () => {
  // Restarts to 10 minutes timer
  timer.restart(getTimestampRange(timeRange));
  timer.pause();
  timerSlow.restart(getTimestampRange(slowTime));
  timerSlow.pause();
  showTimers.value = false;
  goPercent = false;
  calculateTimes();
  drift = 0;
  driftRef.value = 0;
  correction = 0;
  correctionRef.value = 0;
  terminalCounter = 0;
  currentCounter.value = slowTime;
  slowFast = true;
  percentUp.value = 0;
  percentUpText.value = "0";
  littlePercentRef.value = "0";
  correction = 0;
  correctionRef.value = 0;
  clearTimeout(tickTimer);
  tickRunning = false;
};
const startTimers = () => {
  slowFast = true;
  currentCounter.value = slowTime;
  clearTimeout(tickTimer);
  //tick();
  tickRunning = false;
  const start = getTimestampRange(0);
  const end = getTimestampRange(timeRange);
  //time.setSeconds(time.getSeconds() + timeRange);
  timer.restart(getTimestampRange(timeRange));
  //timer.start();
  timerSlow.restart(getTimestampRange(slowTime));
  //timerSlow.start();
  goPercent = true;
  computePercent(start, end);
  calculateTimes();
  running = true;
  drift = 0;
  driftRef.value = 0;
  correction = 0;
  correctionRef.value = 0;
  slowTotalRef.value = slowTimes;
  fastTotalRef.value = fastTimes;
  terminalCounter = 0;
  percentUp.value = 0;
  percentUpText.value = "0";
  littlePercentRef.value = "0";
  correction = 0;
  correctionRef.value = 0;
};
const pauseTimers = () => {
  timer.pause();
  timerSlow.pause();
  goPercent = false;
  terminalCounter = 0;
  clearTimeout(tickTimer);
  tickRunning = false;
};
const resumeTimers = () => {
  timer.resume();
  timerSlow.resume();
  const start = getTimestampRange(0);
  console.log(progress);
  const end = getTimestampRange(timeRange - progress / 1000);
  goPercent = true;
  running = true;
  computePercent(start, end);
  drift = 0;
  driftRef.value = 0;
  correction = 0;
  correctionRef.value = 0;
  terminalCounter = 0;
  correction = 0;
  correctionRef.value = 0;
  clearTimeout(tickTimer);
  //tick();
  tickRunning = false;
};
const computePercent = (start: number, end: number) => {
  const now = new Date();
  const nowTimestamp = now.getTime();
  const total = end - start;
  progress = nowTimestamp - start;
  const p = 99.99 - (progress / total) * 100;
  percentUp.value = (progress / total) * 100;
  percentUpText.value = percentUp.value.toFixed(2).split(".")[0];
  percent.value = p;
  const percentText = p.toFixed(2).split(".");
  percentDec.value = percentText[0];
  if (percentDec.value.length < 2) percentDec.value = "0" + percentDec.value;
  percentFrac.value = percentText[1];
  if (percentFrac.value.length < 2) percentFrac.value = "0" + percentFrac.value;
  if (goPercent === true) {
    setTimeout(() => {
      computePercent(start, end);
    }, 100);
  }
};
function calculateTimes() {
  let sum = 0;
  let slowFast = false;
  slowTimes = -1;
  fastTimes = 0;
  while (sum < timeRange) {
    if (slowFast === false) {
      sum += slowTime;
      slowTimes++;
      slowFast = !slowFast;
    } else {
      sum += fastTime;
      fastTimes++;
      slowFast = !slowFast;
    }
  }
}
function saveTimeRange() {
  localStorage.setItem("timeRaange", timeRange.toString());
}
function saveTimeStints() {
  slowTime = slowTimeRef.value;
  fastTime = fastTimeRef.value;
  localStorage.setItem("slow", slowTime.toString());
  localStorage.setItem("fast", fastTime.toString());
}
function setTimeRanege(range: number) {
  timeRange = range;
  selected.value = timeRange;
}
function setCorrection(c: number) {
  correction += c;
  //currentCounter.value += c;
  correctionRef.value += c;
}
function tick() {
  //console.log("tick", slowFast, currentCounter.value);
  currentCounter.value--;
  if (currentCounter.value < 1) {
    slowFast = !slowFast;
    if (slowFast === true) {
      currentCounter.value = slowTime + correction;
      slowTimes--;
      swap.value = false;
      correction = 0;
      correctionRef.value = 0;
    } else {
      currentCounter.value = fastTime + correction;
      fastTimes--;
      swap.value = true;
      correction = 0;
      correctionRef.value = 0;
    }
  }
  if (slowFast === true) {
    const p = 99.99 - (currentCounter.value / slowTime) * 100;
    littlePercentRef.value = p.toFixed(2).split(".")[0];
  } else {
    const p = 99.99 - (currentCounter.value / fastTime) * 100;
    littlePercentRef.value = p.toFixed(2).split(".")[0];
  }
  let now = new Date();
  lastTick = now.getTime();
  difference += lastTick - lastTime;
  console.log(lastTick - lastTime, difference);
  tickTimer = setTimeout(tick, 1000);
}
watchEffect(async () => {
  if (timer.isExpired.value) {
    console.warn("IsExpired");
    timerSlow.restart(getTimestampRange(timeRange));
    timerSlow.pause();
    showTimers.value = false;
    goPercent = false;
    terminalCounter = 0;
    currentCounter.value = slowTime;
    slowFast = true;
    percentUp.value = 0;
    percentUpText.value = "0";
    littlePercentRef.value = "0";
    swap.value = false;
    correction = 0;
    correctionRef.value = 0;
    clearTimeout(tickTimer);
  }
  if (timer.seconds.value) {
    let now = new Date();
    //console.log(now.getTime() - lastTime);
    lastTime = now.getTime();
    if (tickRunning === false) {
      tickRunning = true;
      tick();
    }
    //currentCounter.value--;
    //console.log(slowFast, currentCounter.value);
    /*
    if (currentCounter.value < 1) {
      slowFast = !slowFast;
      if (slowFast === true) {
        currentCounter.value = slowTime + correction;
        slowTimes--;
        swap.value = false;
        correction = 0;
        correctionRef.value = 0;
      } else {
        currentCounter.value = fastTime + correction;
        fastTimes--;
        swap.value = true;
        correction = 0;
        correctionRef.value = 0;
      }
    }
    if (slowFast === true) {
      const p = 99.99 - (currentCounter.value / slowTime) * 100;
      littlePercentRef.value = p.toFixed(2).split(".")[0];
    } else {
      const p = 99.99 - (currentCounter.value / fastTime) * 100;
      littlePercentRef.value = p.toFixed(2).split(".")[0];
    }
      */
  }
  /*
  if (timerSlow.isExpired.value) {
    console.warn("Slow IsExpired");
    terminalCounter++;
    if (running === true) {
      running = false;
      stopDate = new Date();
    }
  }
  if (timerSlow.seconds.value) {
    //console.warn("Slow running");
    timer1 = new Date();
  }
  if (timer.seconds.value) {
    if (currentCounter.value < 1) {
      slowFast = !slowFast;
      if (slowFast === true) {
        currentCounter.value = slowTime;
      } else {
        currentCounter.value = fastTime;
      }
    }
    currentCounter.value = currentCounter.value - 1;
    console.log(slowFast, currentCounter.value);
    if (timerSlow.isExpired.value) {
      calculate = true;
      console.warn("Slow IsExpired2");
      goTimer.value = true;
      console.log("tCounter", terminalCounter);
      terminalCounter = 0;
    }
    //console.log("drift", drift);
    if (goTimer.value === true) {
      running = true;
      goTimer.value = false;
      if (drift >= 1000) {
        correction += drift * -1;
        console.log("correction", correction);
        correctionRef.value = correction;
      }
      if (swap.value === false) {
        timerSlow.restart(getTimestampRange(fastTime, correction));
        fastTimes--;
        swap.value = true;
      } else {
        timerSlow.restart(getTimestampRange(slowTime, correction));
        slowTimes--;
        swap.value = false;
      }
    }
    if (calculate === true) {
      if (correction != 0) {
        drift = 0;
        correction = 0;
        correctionRef.value = correction;
        console.log("drift", drift, "correction", correction);
      }
      innerDate = new Date();
      drift += innerDate.getTime() - stopDate.getTime();
      console.log("drift calculate", drift);
      driftRef.value = drift;
      calculate = false;
    }
    timer2 = new Date();
    console.log("Timer diff", timer2.getTime() - timer1.getTime());
  }
    */
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.intervalSlow {
  background-color: #ddddff;
}
.intervalFast {
  background-color: #ffdddd;
}
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}
.flexb {
  display: flex;
  width: 200px;
  padding-left: 50px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 10px;
}
.flexc {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
}
.center {
  text-align: center;
}
.padding {
  padding: 0 50px;
}
.full {
  /*display: flex;
  flex-direction: collumn;
  flex-wrap: no-wrap;
  justify-content: center;
  gap: 30px;*/
  width: 100%;
  padding: 10px;
  margin: 10px;
}
.selected {
  background-color: rgb(176, 249, 170);
}
.range {
  position: relative;
  background-color: #333;
  width: 100%;
  height: 30px;
  transform: skew(30deg);
  font-family: "Orbitron", monospace;
}
.range:before {
  --width: calc(var(--p) * 1%);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #f3e600;
  z-index: 0;
  animation: load 0.5s forwards linear;
}
.range:after {
  counter-reset: progress var(--p);
  content: counter(progress) "%";
  color: rgb(171, 116, 61);
  position: absolute;
  left: 5%;
  top: 50%;
  transform: translateY(-50%) skewX(-30deg);
  z-index: 1;
}

.range__label {
  transform: skew(-30deg) translateY(-100%);
  line-height: 1.5;
}

@keyframes load {
  to {
    width: var(--width);
  }
}
.noMargin {
  margin: 0px;
}
</style>
